<template>
    <div class="message-error p-invalid">
        <div class="txt-error" v-if="(store.error && !store.error_data?.user)">
            {{ msgErrors.user }}
        </div>
        <div class="txt-error" v-else-if="store.error_data?.user">
            {{ store.error_data.user }}
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import useStore from "../../../../../store";

export default {

    setup(props, { emit }) {
        const store = useStore();
        const msgErrors = computed(() => store.error ? (store.error.data ? JSON.parse(store.error.data) : {user: store.error.errormessage}) : {})

        

        return{
            store,
            msgErrors,
        }
    },
};
</script>


<style lang="scss" scoped>
.message-error {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px 0px 0px 0px;
    .messages{
        font-size: 12px;
        margin-top: 4px;
        margin-left: 6px;
    }
}
.txt-error{
    font-size: 14px;
    font-weight: 700;
    text-shadow: #FF6A33 0pc 0pc 0.1rem;
}


:deep() {
    .p-inputtext{
        color: #2D313D;
        padding: 0 16px;
        height: 50px;
        &::placeholder{
            color: #BDBDBD;       
        }
    }
}
</style>