<template>
    <div class="login-form">
        <InputText
            :value="modelValue"
            class=""
            :class="conditionError.class"
            type="text"
            placeholder="E-mail ou telefone"
            :maxlength="100"
            @input="(e) => $emit('update:modelValue', e.target.value)"
            @keyup.enter="submit"
        />
        <div class="messages" v-if="conditionError.status">
            <p class="p-invalid">
                <!-- {{ msgErros.firstName[0] }} -->
            </p>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";

export default {
    props: ["modelValue", "msgErros", "submit"],
    setup(props, { emit }) {
        const text = ref("");

        const conditionError = computed(() => {
            // if( props.msgErros.firstName.length ){
            //     if( props.msgErros.firstName[0] === "Nome deve ser informado." && !props.modelValue ){
            //         return { status: true, class: 'p-invalid' }
            //     }
            //     if( props.msgErros.firstName[0] === "Nome deve possuir no mínimo 2 caracteres." && props.modelValue.length < 2 ){
            //         return { status: true, class: 'p-invalid' }
            //     }
            // }
            return { status: false, class: '' }
        })

        return{
            text,
            conditionError
        }
    },
};
</script>


<style lang="scss" scoped>
.login-form {
    width: 100%;
    ul{
        margin: 0;
        padding: 0;
    }

    .messages{
        font-size: 14px;
        margin-top: 4px;
        margin-left: 6px;
    }
}


:deep() {
    .p-inputtext{
        color: #2D313D;
        font-size: 14px !important;
        font-weight: 400 !important;
        padding: 0 16px;
        height: 50px;
        &::placeholder{
            font-size: 14px;
            font-weight: 300 !important;
            color: #BDBDBD;
        }
        &:focus{
            border: 1px dashed #FF6A33  !important;
            box-shadow: 0px 2px 8px rgb(45, 49, 61, .6)  !important;
        }
    }
}
</style>