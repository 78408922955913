<template>
    <div class="ddi-form">     
        <Dropdown
            v-model="postalCode"
            :class="conditionError.class"
            :options="countryList"
            optionLabel="name"
            placeholder="Selecione o país"
            @change="updateCountryCode"
        />
        <div class="messages" v-if="conditionError.status">
            <p class="p-invalid">
                <!-- {{ msgErros.firstName[0] }} -->
            </p>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";

const countryList = [
    { name: "Brasil +55", code: "+55" },
    { name: "Estados Unidos +1", code: "+1" },
];

export default {
    props: ["modelValue", "msgErros"],
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const postalCode = ref({ name: "Brasil +55", code: "+55" });
        const text = ref("");

        const conditionError = computed(() => {
            // if( props.msgErros.firstName.length ){
            //     if( props.msgErros.firstName[0] === "Nome deve ser informado." && !props.modelValue ){
            //         return { status: true, class: 'p-invalid' }
            //     }
            //     if( props.msgErros.firstName[0] === "Nome deve possuir no mínimo 2 caracteres." && props.modelValue.length < 2 ){
            //         return { status: true, class: 'p-invalid' }
            //     }
            // }
            return { status: false, class: '' }
        })

        const updateCountryCode = (e) => {         
            if(e.value?.code){
                emit("update:modelValue", e.value.code);
            }else{
                emit("update:modelValue", '');
            }
        }

        return{
            postalCode,
            text,
            conditionError,
            countryList,
            updateCountryCode,
        }
    },
};
</script>


<style lang="scss" scoped>
.ddi-form {
    width: 100%;
    ul{
        margin: 0;
        padding: 0;
    }
    .messages{
        font-size: 12px;
        margin-top: 4px;
        margin-left: 6px;
    }
}


:deep(){
    .p-inputtext {
        color: #2D313D;
        padding: 0 16px;
        height: 50px;
        font-size: 14px;
        font-weight: 700;
        &::placeholder{
            color: #BDBDBD;
        }
    }
    .p-inputwrapper-focus{
        border: 1px dashed #FF6A33  !important;
        box-shadow: 0px 2px 8px rgb(45, 49, 61, .6)  !important;
    }
    .p-dropdown-label {
        display: flex;
        align-items: center;
    }
    .p-dropdown-trigger-icon {
        font-size: 14px;
        margin-right: 10px;
    }
    .p-dropdown-panel{
        padding-top: 8px !important;
        margin-top: 8px !important;
    }
}
</style>