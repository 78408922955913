<template>
    <div class="password-form">
        <div class="p-input-icon-right">
            <i 
                class="pi pi-eye"
                v-tooltip.top="{ value: viewPassword ? 'Ocultar senha' : 'Mostrar senha' }"
                @click="viewPassword = !viewPassword"
            />
            <InputText
                :value="modelValue"
                :class="conditionError.class"
                :type="!viewPassword ? 'password' : 'text'"
                placeholder="Senha"
                :maxlength="100"
                @input="(e) => $emit('update:modelValue', e.target.value)"
                @keyup.enter="submit"
            />
        </div>
        <MessageError />
    </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import MessageError from "./MessageError";
import { useRouter } from "vue-router";

export default {
    props: ["modelValue", "msgErros", "submit"],
    components: { MessageError },
    setup(props, { emit }) {
        let router = useRouter();
        const text = ref("");
        const viewPassword = ref(false);
        

        const conditionError = computed(() => {
            // if( props.msgErros.firstName.length ){
            //     if( props.msgErros.firstName[0] === "Nome deve ser informado." && !props.modelValue ){
            //         return { status: true, class: 'p-invalid' }
            //     }
            //     if( props.msgErros.firstName[0] === "Nome deve possuir no mínimo 2 caracteres." && props.modelValue.length < 2 ){
            //         return { status: true, class: 'p-invalid' }
            //     }
            // }
            return { status: false, class: '' }
        })

        const forgot = () => {
            window.localStorage.clear();
            router.push('/account/recover');            
        }

        return{
            text,
            conditionError,
            forgot,
            viewPassword,
        }
    },
};
</script>


<style lang="scss" scoped>
.password-form {
    width: 100%;


    ul{
        margin: 0;
        padding: 0;
    }
    .messages{
        font-size: 12px;
        margin-top: 4px;
        margin-left: 6px;
    }
    .forgot{
        margin-top: 8px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: #FF4500;
        span{
            cursor: pointer;
            &:hover{
                text-shadow: #FF6A33 0px 0px 0.1rem;
            }
        }
    }
}

:deep(){
    .pi-eye{
        cursor: pointer;
    }
    .p-inputtext {
        color: #2D313D;
        font-size: 14px !important;
        font-weight: 400 !important;
        padding: 0 16px;
        height: 50px;
        &::placeholder{
            font-size: 14px;
            font-weight: 300 !important;
            color: #BDBDBD; 
        }
        &:focus{
            border: 1px dashed #FF6A33  !important;
            box-shadow: 0px 2px 8px rgb(45, 49, 61, .6)  !important;
        }
    }
    .p-input-icon-right {
        width: 100%;
        .pi{
            margin-right: 10px;
        }
    }

}
</style>